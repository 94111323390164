<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                        class="wizard wizard-2"
                        id="kt_wizard_v2"
                        data-wizard-state="step-first"
                        data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <div class="row">
                            <div class="col-6">
                                <h4>
                                    <router-link :to="{name:'dashboard'}">
                                        <i class="fas fa-arrow-left"></i>
                                    </router-link>
                                    My Courses
                                </h4>
                            </div>
                            <div class="col-6 text-right">
<!--                                <router-link :to="{name:'course-new'}" class="btn btn-primary ml-2">-->
<!--                                   -->
<!--                                    Add Program-->
<!--                                </router-link>-->
                            </div>
                            <div class="col-12">
                                <div class="">
                                    <table class="table table-stripe">
                                        <thead>
                                        <th>Subject Code</th>
                                        <th>Title</th>
                                        <th>Duration</th>
                                        <th>Credit Hr</th>
                                        <th>Grade</th>
                                        <th>Program</th>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(course, index) of courses" :key="index">
                                            <td>
                                                {{course.subject_code}}
                                            </td>
                                            <td>
                                                <a class="link">
                                                    {{course.title}}
                                                </a>
                                            </td>
                                            <td>
                                                {{`${course.duration} ${course.duration_unit}`}}
                                            </td>
                                            <td>

                                                {{`TH: ${course.theory_credit_hours} PR: ${course.practical_credit_hours}`}}
                                            </td>
                                            <td>
                                                    {{course.grade.title}}

<!--                                                <span :class="course.display_in_enrollment?'badge badge-info':'badge badge-warning'">{{course.display_in_enrollment?'Open':'Closed'}}</span>-->
                                            </td>
                                            <td>
                                                {{course.program.title}}
                                            </td>
<!--                                            <td>-->

<!--                                                <span :class="course.display_in_enrollment?'badge badge-info':'badge badge-warning'">{{course.display_in_enrollment?'Open':'Closed'}}</span>-->
<!--                                            </td>-->

                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
<!--                                        <b-pagination-->
<!--                                                @input="getPrograms"-->
<!--                                                v-model="page"-->
<!--                                                :total-rows="total"-->
<!--                                                :per-page="perPage"-->
<!--                                                first-number-->
<!--                                                last-number-->
<!--                                        ></b-pagination>-->
                                        <!--  -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
    import CoursesService from "@/core/services/courses/CoursesService";
    import UserService from "@/core/services/user/UserService";

    const courseService = new CoursesService();
    const userService = new UserService();

    export default {
        name: "Student-Courses",
        data() {
            return {
                courses: [],
                user: null,
                studentSetting: null,
            }
        },
        mounted() {
            this.getUser();
        },
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
        },
        methods: {
            getCourse() {
                courseService.getByProgram(this.studentSetting.program_id).then(response => {
                    this.courses = response.data.courses;
                });
            },
            getUser() {
                userService.show(this.currentUser.id).then(response => {
                    this.user = response.data.user;
                    this.user.is_active ? (this.user.is_active = true) : false;
                    this.user.is_email_verified
                        ? (this.user.is_email_verified = true)
                        : false;
                    this.studentSetting = this.user.setting ? this.user.setting : {}
                    this.getCourse();
                });
            },
        },
    }
</script>

<style scoped>

</style>
